const IG_BUSINESS = 'IG_BUSINESS'
const IG_PERSONAL = 'IG_PERSONAL'
const FB = 'FB'
const LINKEDIN_PERSONAL = 'LINKEDIN_PERSONAL'
const LINKEDIN_PAGES = 'LINKEDIN_PAGES'
const PINTEREST = 'PINTEREST'
const TIKTOK = 'TIKTOK'
const YOUTUBE = 'YOUTUBE'
const NUDE = 'Nude'

const platforms = {
  IG_BUSINESS,
  IG_PERSONAL,
  FB,
  LINKEDIN_PERSONAL,
  LINKEDIN_PAGES,
  TIKTOK,
  PINTEREST,
  YOUTUBE,
  NUDE
} as const

const platformText = {
  IG_BUSINESS: 'Instagram',
  IG_PERSONAL: 'Instagram',
  FB: 'Facebook',
  LINKEDIN_PERSONAL: 'LinkedIn',
  LINKEDIN_PAGES: 'LinkedIn',
  TIKTOK: 'TikTok',
  PINTEREST: 'Pinterest',
  YOUTUBE: 'YouTube',
  Nude: 'Nude'
}

const platformIconNames = {
  IG_BUSINESS: 'InstagramIcon',
  IG_PERSONAL: 'InstagramIcon',
  FB: 'FacebookIcon',
  LINKEDIN_PERSONAL: 'LinkedinIcon',
  LINKEDIN_PAGES: 'LinkedinIcon',
  PINTEREST: 'PinterestIcon',
  TIKTOK: 'TikTokIcon',
  YOUTUBE: 'YoutubeIcon',
  Nude: 'InstagramIcon'
}

const platformGridIconNames = {
  IG_BUSINESS: 'InstagramGridIcon',
  IG_PERSONAL: 'InstagramGridIcon',
  FB: '',
  LINKEDIN_PERSONAL: '',
  LINKEDIN_PAGES: '',
  PINTEREST: 'PinterestGridIcon',
  TIKTOK: 'TikTokGridIcon',
  YOUTUBE: 'YoutubeIcon',
  Nude: 'InstagramGridIcon'
}

const displayPlatforms = {
  IG: 'IG',
  FB: 'FB',
  LINKEDIN: 'LINKEDIN',
  TIKTOK: 'TIKTOK',
  PINTEREST: 'PINTEREST',
  YOUTUBE: 'YOUTUBE'
}

export { platforms, displayPlatforms, platformText, platformIconNames, platformGridIconNames }
